import { Action } from '@ngrx/store/src/models';

import { environment } from '@env/environment';
import { REDUCERS_KEYS, SANITIZED_ACTIONS } from '@app-store-const/app-store.const';

export function appActionSanitizer(action: Action): Action {
  if (environment.sanitizeNgrx) return { type: action.type };

  if (SANITIZED_ACTIONS.some(it => action.type.includes(it))) return { type: action.type };

  return action;
}

export function appStateSanitizer(state: any): any {
  if (environment.sanitizeNgrx) return {};

  if (state.orderCreatePage) {
    return {
      ...state,
      orderCreatePage: {
        ...state.orderCreatePage,
        [REDUCERS_KEYS.orderTask]: '<< LONG_DATA >>',
        [REDUCERS_KEYS.orderDraft]: '<< LONG_DATA >>',
        [REDUCERS_KEYS.orderItems]: '<< LONG_DATA >>',
        [REDUCERS_KEYS.orderDocuments]: '<< LONG_DATA >>',
      },
    };
  }

  return state;
}
