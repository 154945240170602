import { createFeatureSelector, createReducer } from '@ngrx/store';

export const baseFeatureKey = 'baseData';

export interface BaseState {
  error: any;
}

export const initialState: BaseState = {
  error: null,
};

export const baseState = createFeatureSelector<BaseState>(baseFeatureKey);

export const baseReducer = createReducer(
  initialState,
);
