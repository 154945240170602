export const CUSTOM_DATE_ADAPTER = {
  parse: {
    dateInput: ['MM/DD/YYYY', 'MM/DD/YYYY'],
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
