import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { LoadingModule } from '@directives/loading/loading.module';
import { SafePipeModule } from '../../pipes/safe/safe-pipe.module';
import { ModalHeaderModule } from '@components/modal-header/modal-header.module';
import { AgreementsModalComponent } from '@components/agreements-modal/components/agreements-modal/agreements-modal.component';

@NgModule({
  imports: [
    CommonModule,
    LoadingModule,
    SafePipeModule,
    MatButtonModule,
    MatDialogModule,
    ModalHeaderModule,
  ],
  declarations: [
    AgreementsModalComponent,
  ],
  exports: [
    AgreementsModalComponent,
  ],
})
export class AgreementsModalModule {}
