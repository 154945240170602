import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../../pages/module/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'prescriber',
    loadChildren: () => import('../../prescriber/module/prescriber.module').then(m => m.PrescriberModule),
  },
  {
    path: 'authorization',
    loadChildren: () => import('../../authentication/module/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'icons',
    loadChildren: () => import('../../shared/pages/icons-page/icons-page.module').then(module => module.IconsPageModule),
  },
  {
    path: 'swagger',
    loadChildren: () => import('../../shared/pages/swagger-page/swagger-page.module').then(module => module.SwaggerPageModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('../../shared/pages/not-found-page/not-found-page.module').then(module => module.NotFoundPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
