import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { BaseRequestModel } from '@models/request';
import { HcpcsCode, HcpcsCodeAPI } from '@models/hcpcs';
import { UrlService } from '@services/url.service/url.service';
import { RequestService } from '@services/request.service/request.service';
import { ErrorHandlingService } from '@services/error-handling.service/error-handling.service';

@Injectable()
export class HcpcsService {
  constructor(
    private readonly urlService: UrlService,
    private readonly requestService: RequestService,
    private readonly errorHandlingService: ErrorHandlingService,
  ) {}

  public getHcpcsCodes$(hcpcsCodes: string[]): Observable<HcpcsCode[]> {
    return this.requestService
      .get(this.urlService.getHcpcsCodesURL(hcpcsCodes))
      .pipe(
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        map((data: HcpcsCodeAPI[]) => data.map(it => HcpcsCode.getDataFromAPI(it))),
        catchError((err) => this.errorHandlingService.handleError(err)));
  }

}
