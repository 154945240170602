import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { LoadingModule } from '@directives/loading/loading.module';
import { ModalHeaderModule } from '../modal-header/modal-header.module';
import { ConfirmModalComponent } from './components/confirm-modal.component';

@NgModule({
  imports: [
    CommonModule,
    LoadingModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    ModalHeaderModule,
  ],
  declarations: [
    ConfirmModalComponent,
  ],
  exports: [
    ConfirmModalComponent,
  ],
})
export class ConfirmModalModule {}
