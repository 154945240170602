import { of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions/npi.actions';
import * as baseActions from '@actions/base.actions';

import { NpiService } from '@services/npi.service/npi.service';

@Injectable()
export class NpiEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly npiService: NpiService,
  ) {}

  getNpi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getNpi),
      switchMap(({ npiSearchModel }) =>
        this.npiService.getNpiData$(npiSearchModel)
          .pipe(
            map(npiData => actions.getNpiSuccess({ npiData })),
            catchError(error => of(
              baseActions.logError({ error }),
              actions.getNpiFailure({ error }))),
          )),
    ),
  );
}
