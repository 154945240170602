<div class="custom-modal">
	<modal-header title="Agreements"
	              [isCloseBtn]="false"
	              (closeModal)="dialogRef.close(null)"></modal-header>
	<div class="custom-modal__content _alt-bg _min-height-160"
	     [pending]="documentsPending$ | async">
		<ul class="_line-height-base-14">
			<li *ngFor="let document of documents$ | async | slice : 0 : 1"
			    class="base-page__content-item _it-space-24 _layout-gap-y-36 _text-primary">
				<h4 class="base-page__content-item-title _mb-24 _text-primary _text-center">{{document.name}}</h4>
				<div class="base-page__content-item-body">
					<div class="markdown-body"
					     [innerHTML]="document.markdownData | safePipe : 'html'"></div>
					<div class="_d-f _justify-end _pv-24 _mt-12">
						<button class="_btn-size-s _min-width-145px _spinner-xs"
						        mat-flat-button
						        id="addProduct"
                                type="button"
                                [color]="'alternative' // noinspection ValidateTypes"
                                [pending]="document.isPending"
						        (click)="acceptAgreement(document.id)">Accept</button>
					</div>
				</div>
			</li>
		</ul>
	</div>
</div>
