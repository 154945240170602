import { URLS } from '@const/urls';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { STORAGE } from '@const/storage';
import { EnvConfig } from '@models/env-config';
import { ENV_CONFIG_URL, SVG_URL } from '@const/base-const';
import { StorageService } from '@services/storage.service/storage.service';
import { AUTO_LOGOUT_DATA, AUTO_LOGOUT_TYPES } from '@const/auto-logout-types';
import { EnvConfigService } from '@services/env-config.service/env-config.service';
import { UnSubscribeDirective } from '@directives/un-subscribe/un-subscribe.directive';
import { ApplicationInsightsService } from '@services/application-insights.service/application-insights.service';

@Injectable()
export class EnvConfigInterceptor extends UnSubscribeDirective implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly envConfigService: EnvConfigService,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {
    super();
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (EnvConfigInterceptor.isScipConfigCheck(request.url)) return next.handle(request);

    return this.envConfigService
      .getEnvConfig$()
      .pipe(switchMap(envConfig => {
        const baseHttpEvent = new Observable<HttpEvent<unknown>>();

        const newEnvConfig: EnvConfig = envConfig;
        const currentEnvConfig: EnvConfig = this.envConfigService.getCurrentEnvConfig();

        if (!currentEnvConfig) {
          this.envConfigService.setNewEnvConfig(newEnvConfig);

          return next.handle(request);
        }

        if (currentEnvConfig.applicationVersion !== newEnvConfig.applicationVersion) {
          this.envConfigService.setNewEnvConfig(newEnvConfig);
          this.resetUserDataAndRefreshApp();

          return baseHttpEvent;
        }

        this.envConfigService.setNewEnvConfig(newEnvConfig);

        return next.handle(request);
      }));
  }

  private resetUserDataAndRefreshApp(): void {
    this.storageService.remove(STORAGE.userData);
    this.storageService.remove(STORAGE.accessToken);
    this.storageService.remove(STORAGE.refreshToken);
    this.applicationInsightsService.clearUserContext();

    this.router
      .navigate([URLS.login], { queryParams: { [AUTO_LOGOUT_DATA.queryParameter]: AUTO_LOGOUT_TYPES.sessionTimeout } })
      // @ts-expect-error
      .finally(() => window.location.reload(true)); // todo: check how to reload page with params
  }

  private static isScipConfigCheck(url: string): boolean {
    return EnvConfigInterceptor.isConfigRequest(url) || EnvConfigInterceptor.isSvgRequest(url);
  }

  private static isConfigRequest(url: string): boolean {
    return url.indexOf(ENV_CONFIG_URL) !== -1;
  }

  private static isSvgRequest(url: string): boolean {
    return url.indexOf(SVG_URL) !== -1;
  }
}
