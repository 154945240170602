import { Router } from '@angular/router';

import { URLS } from '@const/urls';
import { STORAGE } from '@const/storage';
import { UserData } from '@models/user-profile';
import { setStorageRedirectUrl } from '@helpers/url.helper';
import { StorageService } from '@services/storage.service/storage.service';
import { UserDataService } from '@services/user-data.service/user-data.service';
import { EnvConfigService } from '@services/env-config.service/env-config.service';

export function InitApp(storageService: StorageService, router: Router, userDataService: UserDataService, envConfigService: EnvConfigService): Function {
  return () => new Promise((resolve) =>
    envConfigService.getEnvConfig$().subscribe(envConfig => {
      envConfigService.setNewEnvConfig(envConfig);

      const userData: UserData = storageService.get(STORAGE.userData);

      if (userData) {
        userDataService.setUserData(userData);
      }

      const skipUrls = [URLS.authorization, URLS.prescriber];

      if (!userData && !skipUrls.some(it => window.location.href.includes(it))) {
        setStorageRedirectUrl();
        router.navigate([URLS.authorization]).then();
      }

      resolve(true);
    }));
}
