import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ApplicationInsightsService } from '@services/application-insights.service/application-insights.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private errorCount: number = 0;
  private maxErrors: number = 10;

  constructor(
    private injector: Injector,
  ) {}

  public handleError(error: any): void {
    this.errorCount = this.errorCount + 1;

    if (this.errorCount < this.maxErrors) {
      this.injector.get<ApplicationInsightsService>(ApplicationInsightsService)
        && this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);

      console.log(error.originalError || error);

      throw error;
    }
  }
}
