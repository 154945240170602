import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, finalize } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AgreementDoc } from '@models/agreement-doc';
import { AgreementsService } from '@services/agreements.service/agreements.service';
import { UnSubscribeDirective } from '@directives/un-subscribe/un-subscribe.directive';

@Component({
  selector: 'agreements-modal',
  templateUrl: './agreements-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementsModalComponent extends UnSubscribeDirective implements OnInit {
  public documentsPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public documents$: BehaviorSubject<AgreementDoc[]> = new BehaviorSubject<AgreementDoc[]>([]);

  constructor(
    public dialogRef: MatDialogRef<AgreementsModalComponent>,
    private readonly agreementsService: AgreementsService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.getDocuments();
  }

  public acceptAgreement(id: number): void {
    this.documents$.next(this.documents$.value.map(it => it.id === id ? { ...it, isPending: true } : it));

    this.agreementsService
      .acceptAgreement$(id)
      .subscribe(() => this.documents$.value.length > 1 ? this.documents$.next(this.documents$.value.filter(it => it.id !== id)) : this.dialogRef.close());
  }

  private getDocuments(): void {
    this.documentsPending$.next(true);

    this.agreementsService
      .getUnsignedAgreements$()
      .pipe(takeUntil(this.ngUnSubscribe), finalize(() => this.documentsPending$.next(false)))
      .subscribe(data => this.documents$.next(data));
  }
}
