import MARKDOWN_IT from 'markdown-it';

export class AgreementDocAPI {
  id: number;
  name: string;
  content: string;
  updatedMemo: string;
  createdById: string;
  versionNumber: number;
  createdDateTime: string;
  formattedVersion: string;
  updatedDateTime: string;
}

export class AgreementDoc {
  id: number;
  name: string;
  content: string;
  updatedMemo: string;
  createdById: string;
  versionNumber: number;
  createdDateTime: string;
  formattedVersion: string;
  updatedDateTime: string;

  // FE only
  isPending: boolean;
  markdownData: string;

  public static getDataFromAPI(data: AgreementDocAPI): AgreementDoc {
    return {
      id: data.id,
      name: data.name,
      content: data.content,
      updatedMemo: data.updatedMemo,
      createdById: data.createdById,
      versionNumber: data.versionNumber,
      createdDateTime: data.createdDateTime,
      formattedVersion: data.formattedVersion,
      updatedDateTime: data.updatedDateTime,

      // FE only
      isPending: false,
      markdownData: MARKDOWN_IT().render(data.content),
    };
  }
}
