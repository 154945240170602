import { Router } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxPaginationModule } from 'ngx-pagination';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatSelectModule } from '@angular/material/select';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { InitApp } from '@inits/init-app';
import { SERVICES } from '@services/index';
import { BASE_EFFECTS } from '@effects/index';
import { environment } from '@env/environment';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app.routing.module';
import { metaReducers, ROOT_REDUCERS } from '@reducers/index';
import { CUSTOM_DATE_ADAPTER } from '@const/custom-date-adapter';
import { LoadingModule } from '@directives/loading/loading.module';
import { TokenInterceptor } from '@interceptors/token.interceptor';
import { GlobalErrorHandler } from '@error-handlers/global-error.handler';
import { StorageService } from '@services/storage.service/storage.service';
import { EnvConfigInterceptor } from '@interceptors/env-config.interceptor';
import { UserDataService } from '@services/user-data.service/user-data.service';
import { EnvConfigService } from '@services/env-config.service/env-config.service';
import { ConfirmModalModule } from '@components/confirm-modal/confirm-modal.module';
import { appActionSanitizer, appStateSanitizer } from '@app-store-help/app-store.helpers';
import { AgreementsModalModule } from '@components/agreements-modal/agreements-modal.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    LoadingModule,
    MatSelectModule,
    AppRoutingModule,
    ConfirmModalModule,
    MatDatepickerModule,
    NgxPaginationModule,
    MatAutocompleteModule,
    AgreementsModalModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot(BASE_EFFECTS),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      name: 'Synapse Dev Tools',
      logOnly: !environment.sanitizeNgrx,
      stateSanitizer: (state) => appStateSanitizer(state),
      actionSanitizer: (action) => appActionSanitizer(action),
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    SERVICES,
    provideEnvironmentNgxMask(),
    {
      provide: APP_INITIALIZER,
      useFactory: InitApp,
      multi: true,
      deps: [StorageService, Router, UserDataService, EnvConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EnvConfigInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_ADAPTER,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
