import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

import { BaseRequestModel } from '@models/request';
import { AgreementDoc, AgreementDocAPI } from '@models/agreement-doc';
import { UrlService } from '@services/url.service/url.service';
import { RequestService } from '@services/request.service/request.service';
import { ErrorHandlingService } from '@services/error-handling.service/error-handling.service';

@Injectable()
export class AgreementsService {
  constructor(
    private readonly urlService: UrlService,
    private readonly requestService: RequestService,
    private readonly errorHandlingService: ErrorHandlingService,
  ) {}

  public getAnyUnsignedDocumentAgreements$(): Observable<boolean> {
    return this.requestService
      .get(this.urlService.getAnyUnsignedDocumentAgreementsURL())
      .pipe(
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        catchError((err) => this.errorHandlingService.handleError(err)));
  }

  public getUnsignedAgreements$(): Observable<AgreementDoc[]> {
    return this.requestService
      .get(this.urlService.getUnsignedDocumentsAgreementsURL())
      .pipe(
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        map((data: AgreementDocAPI[]) => data.map(it => AgreementDoc.getDataFromAPI(it))),
        catchError((err) => this.errorHandlingService.handleError(err)));
  }

  public acceptAgreement$(documentAgreementId: number): Observable<void> {
    return this.requestService
      .post(this.urlService.getAcceptAgreementURL(), { documentAgreementId })
      .pipe(
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        catchError((err) => this.errorHandlingService.handleError(err)));
  }
}
