import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _cache: {[key: string]: Observable<any>} = {};

  public cache<T>(key: string, observable: Observable<T>): Observable<T> {
    return this._cache[key] = this._cache[key] || observable;
  }

  public flush(key: string): void {
    Reflect.deleteProperty(this._cache, key);
  }
}
