import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { BaseRouterStoreState, routerReducer, RouterReducerState } from '@ngrx/router-store';

import { environment } from '@env/environment';
import { npiFeatureKey, npiReducer, NpiState } from '@reducers/npi.reducer';
import { baseFeatureKey, baseReducer, BaseState } from '@reducers/base.reducer';
import { userDataFeatureKey, userDataReducer, UserDataState } from './user-data.reducer';
import { sidebarFeatureKey, sidebarReducer, SidebarState } from '@reducers/sidebar.reducer';
import { mfaModalFeatureKey, mfaModalReducer, MfaModalState } from '@reducers/mfa-modal.reducer';
import { resetPasswordFeatureKey, resetPasswordReducer, ResetPasswordState } from '@auth-reducers/reset-password.reducer';

export interface State {
  router: RouterReducerState<BaseRouterStoreState>;
  [npiFeatureKey]: NpiState;
  [baseFeatureKey]: BaseState;
  [sidebarFeatureKey]: SidebarState;
  [userDataFeatureKey]: UserDataState;
  [mfaModalFeatureKey]: MfaModalState;
  [resetPasswordFeatureKey]: ResetPasswordState;
}

export const ROOT_REDUCERS: ActionReducerMap<State> = {
  router: routerReducer,
  [npiFeatureKey]: npiReducer,
  [baseFeatureKey]: baseReducer,
  [sidebarFeatureKey]: sidebarReducer,
  [userDataFeatureKey]: userDataReducer,
  [mfaModalFeatureKey]: mfaModalReducer,
  [resetPasswordFeatureKey]: resetPasswordReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
