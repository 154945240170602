// @ts-nocheck

export function loadGenesysChat(): void {
  document.head.appendChild(createMessengerScript());
  document.body.appendChild(createMessengerBtn());

  (function (g, e, n, es, ys) {
    g['_genesysJs'] = e;
    g[e] = g[e] || function () {
      // eslint-disable-next-line prefer-rest-params
      (g[e].q = g[e].q || []).push(arguments);
    };
    g[e].t = 1 * new Date();
    g[e].c = es;
    ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
  })(window, 'Genesys', 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js', {
    environment: 'prod-usw2',
    deploymentId: '7ae1bf78-65c3-4e1b-9e86-792c407d08c1',
    debug: false,
  });
}

function createMessengerScript(): HTMLScriptElement {
  const messenger = document.createElement('script');

  messenger.src = 'assets/scripts/messenger/messenger.js';
  messenger.type = 'text/javascript';

  return messenger;
}

function createMessengerBtn(): HTMLButtonElement {
  const messengerBtn = document.createElement('button');

  messengerBtn.classList.add('_d-n-i');
  messengerBtn.classList.add('messenger-btn');
  messengerBtn.setAttribute('id', 'messengerBtn');
  messengerBtn.setAttribute('type', 'button');
  messengerBtn.setAttribute('onclick', 'toggleMessenger()');
  messengerBtn.innerHTML = '<div class="messenger-btn__icon"">' +
    '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 421.2 310.6"><path d="M420.6 39.9 381.3.5c-.7-.7-1.9-.7-2.6 0-26.2 26.5-45.2 59.2-55.2 95.1-3.6 13.1-15.8 22.1-29.4 21.6-13.9-.3-25.3-10.7-28.1-24.3-2.6-13.2-9.6-25.1-19.8-33.8-10.7-9-24.4-13.7-39.7-13.7-28.3 0-49.6 16.4-49.6 40.1.4 6.6 2.6 13 6.3 18.5 8.6 12.6 25.4 20 49.9 21.9 57.1 4.5 84.3 30.9 97 52.2 5 8.5 8.8 17.7 11 27.3 9.2 39.6 29.1 75.9 57.7 104.7.7.7 1.9.7 2.6 0l39.3-39.3c.7-.7.7-1.9 0-2.6-61.5-62.7-61.5-163.1 0-225.8.6-.6.7-1.8-.1-2.5zM.5 270.8l39.3 39.3c.7.7 1.9.7 2.6 0 26.2-26.5 45.2-59.2 55.2-95.1 3.6-13.1 15.8-22.1 29.4-21.6 13.9.3 25.3 10.7 28.1 24.3 2.6 13.1 9.6 25 19.8 33.7 10.7 9 24.4 13.7 39.7 13.7 28.3 0 49.6-16.4 49.6-40.1-.4-6.6-2.6-12.9-6.3-18.4-8.6-12.6-25.3-20-49.9-21.9-57.1-4.5-84.3-30.9-97-52.2-5-8.5-8.8-17.7-11-27.3C91 65.7 71 29.5 42.5.6c-.7-.7-1.9-.7-2.6 0L.5 39.9c-.7.7-.7 1.9 0 2.6 29.6 30.1 46.1 70.7 46.1 112.9S30.1 238.2.5 268.3c-.7.6-.7 1.8 0 2.5z"/></svg>' +
    '</div>' +
    '<span class="messenger-btn__text">Need help?</span>';

  return messengerBtn;
}
