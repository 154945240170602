import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as baseActions from '@actions/base.actions';

@Injectable()
export class BaseEffects {
  constructor(
    private readonly actions$: Actions,
  ) {}

  logError$ = createEffect(
    () => this.actions$.pipe(
      ofType(baseActions.logError),
      tap((error) => console.log(error)),
    ), { dispatch: false },
  );
}
