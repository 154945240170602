import { Injectable, OnDestroy } from '@angular/core';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, tap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { URLS } from '@const/urls';
import { Token } from '@models/token';
import { environment } from '@env/environment';
import { UserAuthData } from '@models/user-auth-data';
import { LOGOUT_CLEAR_DATA, STORAGE } from '@const/storage';
import { setStorageRedirectUrl } from '@helpers/url.helper';
import { ENV_CONFIG_DEFAULT } from '@const/env-config-default';
import { getMillisecondsFromMinutes } from '@helpers/base.helper';
import { StorageService } from '@services/storage.service/storage.service';
import { UserDataService } from '@services/user-data.service/user-data.service';

const EXCLUDED_ADD_TOKEN_URLS: string[] = ['token', 'configs', 'assets', 'GetAllUnits', 'GetAllFrequencies'];

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnDestroy {
  private isRefreshTokenPending: boolean = false;
  private getRefreshTokenIntervalSubscription: NodeJS.Timeout;
  private waitingRefreshTokenRequest: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private readonly storageService: StorageService,
    private readonly userDataService: UserDataService,
  ) {
    this.setGetRefreshTokenInterval();
  }

  public ngOnDestroy(): void {
    clearInterval(this.getRefreshTokenIntervalSubscription);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!TokenInterceptor.isRequestWithToken(request.url)) return next.handle(request);

    const accessToken: string = this.storageService.get(STORAGE.accessToken);
    const refreshToken: string = this.storageService.get(STORAGE.refreshToken);

    return next
      .handle(TokenInterceptor.getRequestWithToken(request, accessToken))
      .pipe(
        catchError(response => {
          if (response instanceof HttpErrorResponse && response.status === 401) {

            if (!this.isRefreshTokenPending) {
              this.isRefreshTokenPending = true;
              this.waitingRefreshTokenRequest.next(null);

              return this.getRefreshToken$(refreshToken)
                .pipe(
                  take(1),
                  switchMap(data => next.handle(TokenInterceptor.getRequestWithToken(request, data.accessToken))),
                  catchError(response => {
                    setStorageRedirectUrl();
                    this.clearUserData();

                    return throwError(response);
                  }),
                );
            }

            return this.waitingRefreshTokenRequest
              .pipe(
                filter(accessToken => accessToken !== null),
                take(1),
                switchMap(accessToken => next.handle(TokenInterceptor.getRequestWithToken(request, accessToken))),
              );
          }

          return throwError(response);
        }),
      );
  }

  private getRefreshToken$(refreshToken: string): Observable<Token> {
    return TokenInterceptor.isPagesWithToken()
      ? this.userDataService
        .getToken$(UserAuthData.getBodyForGetRefreshToken(this.userDataService.getUserData(), refreshToken))
        .pipe(
          tap(() => this.isRefreshTokenPending = false),
          tap(data => this.waitingRefreshTokenRequest.next(data.accessToken)),
          tap(data => this.storageService.set(STORAGE.accessToken, data.accessToken)),
          tap(data => this.storageService.set(STORAGE.refreshToken, data.refreshToken)))
      : of(null);
  }

  private clearUserData(): void {
    window.location.href = environment.url + URLS.login;
    LOGOUT_CLEAR_DATA.forEach(it => this.storageService.remove(it));
  }

  private static isRequestWithToken(url: string): boolean { // todo: need to use only EXCLUDED_ADD_TOKEN_URLS
    if (url.includes('user/me')) return true;

    return !EXCLUDED_ADD_TOKEN_URLS.some(it => url.includes(it)) && TokenInterceptor.isPagesWithToken();
  }

  private static isPagesWithToken(): boolean {
    return !window.location.href.includes(URLS.authorization) && !window.location.href.includes(URLS.prescriber);
  }

  private static getRequestWithToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
      },
    });
  }

  private setGetRefreshTokenInterval(): void {
    this.getRefreshTokenIntervalSubscription =
      setInterval(() => {
        this.storageService.get(STORAGE.refreshToken) && this.userDataService.getUserData()
          && this.getRefreshToken$(this.storageService.get(STORAGE.refreshToken)).subscribe();
      }, getMillisecondsFromMinutes(this.storageService.get(STORAGE.envConfigData)?.refreshTokenInterval || ENV_CONFIG_DEFAULT.refreshTokenInterval));
  }
}
